@import 'theme/variables';


// FONTS
@import url('~/node_modules/typeface-rubik/index.css');

// @import url('~/node_modules/@fortawesome/fontawesome-free/css/all.css');
@import "~/node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~/node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "~/node_modules/@fortawesome/fontawesome-free/scss/brands.scss";


// BOOTSTRAP
@import "~/node_modules/bootstrap/scss/functions";
@import "~/node_modules/bootstrap/scss/variables";
@import "~/node_modules/bootstrap/scss/mixins";
// Optional:
@import "~/node_modules/bootstrap/scss/reboot";
@import "~/node_modules/bootstrap/scss/grid";
@import "~/node_modules/bootstrap/scss/utilities";


// External libs
@import '~/node_modules/sal.js/src/sal';


@import 'theme/utils';
@import 'theme/custom';
