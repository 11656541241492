// $primary:#3484cf;

:root {
  --text-color: #606B90;
  --black: #000;
  --white: #fff;

  --font-family-sans-serif: "Rubik", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // --font-family-monospace: monospace;
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1400px
);

$sal-animation-duration: 0.5s;

$fa-font-path: "~/node_modules/@fortawesome/fontawesome-free/webfonts";