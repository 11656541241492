@import 'variables';

html {
  scroll-behavior: smooth;
}
body {
  font-family: var(--font-family-sans-serif);
  font-size: 1rem;
  color: var(--text-color);
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}
h1,h2,h3 {
  color: var(--black);
}
h1 {
  font-size: 4rem;
  line-height: 76px;

  @media screen and (max-width: 767px) {
    font-size: 3.25rem;
  line-height: 60px;
}
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2rem;
}

b {
  font-weight: 500;
}

a {
  transition: color .1s ease;
  &:hover {
    text-decoration: none;
  }
}

hr {
  border: 0;
    height: 1px;
    background: #f1f1f1;
}

.subtitle {
  font-size: 1.5rem;
  line-height: 28px;
}

.logo {
  background: url('./assets/logos/frankmosca.png') no-repeat center;
  background-size: contain;
  min-width: 115px;
  height: 69px;
}

.social-btns {
  display: flex;
  align-items: center;
  a {
    color: #474D57;
    &:not(:last-child) {
      margin-right: 8px;
    }
    transition: transform .2s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  &.social-btns-gray a {
    color: #6A6A6A;
  }
}
.fa-telegram-square {
  position: relative;
  background: url('data-url:./assets/svgs/telegram-square.svg') no-repeat center;
  background-size: contain;
  width: 42px;
  height: 48px;
  margin: -18px 0;
  &.fa-2x {
    width: 28px;
  height: 32px;
  margin: -9px 0;
  }
}
.fa-telegram-square-gray {
  @extend .fa-telegram-square;
  background-image: url('data-url:./assets/svgs/telegram-square-gray.svg');
}

.nav-header {
  padding: 3rem 0;
  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }

}


.nav-links {
  margin-bottom: 0;
  padding-left: 0;
  font-size: 22px;
  li {
    display: inline-block;
    position: relative;

    a {
      color: var(--black);
      transition: width .5s ease, color .33s ease;
      &:hover {
        &:after {
          width: 100%;
          background-color: var(--text-color);
        }
      }

      &:after {
        // trattino
        content: '';
        background-color: #E7EAEE;
        width: 16px;
        height: 3px;
        position: absolute;
        bottom: -4px;
        left: 0;
        transition: width .33s ease, background-color .2s ease;
      }
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: 767px) {
      font-size: 19px;

      li {
        margin-bottom: 8px;
        &:not(:last-child) {
          margin-right: .5rem;
        }
      }

  }
}


.main-hero {
  position: relative;
  // background: url('data-url:./assets/svgs/pixel-confetti.svg') no-repeat;
  // background-size: 800px; // @temp
  // background-position: calc(100% - 16px) 75%;

  .main-hero-content {
    padding: 4rem 0;
    @media screen and (min-width: 959px) {
        max-width: 50%;
        padding: 6rem 0;
        padding-top: 8rem;
    }
  }

  >.particles-group {
    position: absolute;
    top: 160px;
    right: 16px;
    width: 800px;
    user-select: none;
    overflow: hidden;
    // z-index: -1;
    @media screen and (max-width: 959px) {
      opacity: .5;
    }
  }
}



.section-heading {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 5rem;

  h2 {
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    padding: .8rem 2rem;
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(231, 234, 238, .64); // @temp HARDCODED
      width: 100%;
      height: 100%;
      z-index: -1;
      transform: rotate(-3deg);
    }
  }
}

.product-photo {
  object-fit: contain;
  width: 80%;
  height: 100%;
  max-height: 500px;
  @media screen and (max-width: 767px) {
      max-height: 256px;
      margin-bottom: 2rem;
  }

}


.product-title {
  > div {
    display: inline-block;
  }
  h3 {
    display: inline-block;
    line-height: 1;
    margin-bottom: 0;
  }
  .product-title-line {
    margin: .75rem 0 1rem 0;
    width: 50%;
    height: 4px;
  }
}

.product-description {
  font-size: 20px;
}



.chip-square {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 4px 8px;
  color: var(--white);
  height: 28px;
  background-color: rgba(89, 106, 137, .5); // @temp HARDCODED
}

.chips-group {
  display: flex;
  flex-wrap: wrap;
  .chip-square:not(:last-child) {
    margin-right: .5rem;
    margin-bottom: .25rem;
  }
}

.companies-overwiew {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  >img {
    height: 42px;
    object-fit: contain;
    margin-bottom: 2rem;
    &:not(:first-child) {
      margin-left: 2rem;
    }

    @media screen and (max-width: 1280px) {
        height: 32px;
        &:not(:first-child) {
          margin-left: 1rem;
        }
    }
  }

}


.photo-avatar {
  border-radius: 50%;
  max-width: 246px;
  max-height: 246px;
}

@media screen and (max-width: 991px) {
  .photo-avatar {
    margin-bottom: 2rem;
  }
}


.contact-card {
  margin: 0 auto;
  margin-top: 7rem; // v    v
  margin-bottom: 0; // correzione parallasse
  background: #F6F9FE; // @temp HARDCODED
  padding: 4rem 6.25rem;
  max-width: 1080px;
  width: 90%;
  border-radius: 1rem;
  box-shadow: 0px 3px 6px rgba(27, 52, 77, 0.1);
  @media screen and (max-width: 767px) {
      padding: 3rem 2rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
      &.parallax {
        transform: translate3d(0,0,0) !important;
      }
  }
}



.copy-text {
  color: #9da8b3;
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
  }
}


[name="lang-selector"] {
  &:not(.active) + img {
    filter: grayscale(.75);
    cursor: pointer;
  }
  &.loading + img {
    transform: scale(.9);
  }
  + img {
    transition: filter .1s ease, transform .25s ease;
  }
}